import { AxiosResponse } from 'axios';
import { BaseService } from '../api/axios';
import { HttpHeader } from '../constants/http';

export class TokenService {
	async fetchAuthorizationToken(token: string): Promise<AxiosResponse> {
		return await BaseService.request({
			method: 'GET',
			url: '/check/token',
			headers: {
				[HttpHeader.Authorization]: token
			}
		});
	}
}

export const tokenService = new TokenService();
